import { getTldByLocale, getBrandLocales } from '.'

const getAccountLink = (brand, tld, subDomain, sitePath) =>
  `https://www.${subDomain}${brand}.${tld}${
    sitePath ? `/${sitePath}` : ''
  }/my-account`

const getLinkData = localeItem => {
  const { locale, sitePath } = localeItem
  const tld = getTldByLocale(locale)
  return { tld, sitePath }
}

const getAccountLinkForLocale = (brand, localeItem, subDomain) => {
  const { tld, sitePath } = getLinkData(localeItem)
  return getAccountLink(brand, tld, subDomain, sitePath)
}

const generateLinks = (brand, env) =>
  getBrandLocales(brand).reduce(
    (links, localeItem) => ({
      ...links,
      [localeItem.locale]: getAccountLinkForLocale(
        brand,
        localeItem,
        env.subDomain
      )
    }),
    {}
  )

export const getLinks = (brand, env) => generateLinks(brand, env)
