import React, { useState } from 'react'
import './Menu.css'
import PropTypes from 'prop-types'
import { getMenuItems } from '../../utils/menuItems'

const Menu = props => {
  const [isMenuItemsVisible, showMenuItems] = useState(false)
  const menuItems = getMenuItems(props.brand, props.locale)
  const showMenu = value => {
    showMenuItems(value)
    props.handleMenu(value)
  }

  return (
    <div className="sidebar">
      {isMenuItemsVisible && (
        <div className="opt-out-close-menu">
          <button onClick={() => showMenu(false)}>×</button>
        </div>
      )}

      <div className={`navigation ${isMenuItemsVisible && 'shown'}`}>
        <nav className="navigation-bottom">
          <ul className="navigation-items">
            {menuItems.map(item => (
              <li key={item.key}>
                <a href={item.url}>{item.title}</a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      {!isMenuItemsVisible && (
        <button className="opt-out-menu" onClick={() => showMenu(true)}>
          Menu
        </button>
      )}
    </div>
  )
}
Menu.propTypes = {
  handleMenu: PropTypes.func,
  brand: PropTypes.string,
  locale: PropTypes.string
}

export default Menu
