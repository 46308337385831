const REEBOK = 'RBK';

export default (brand) => {
  const clientId = brand === REEBOK ? window.REEBOK_CLIENT_ID : window.ADIDAS_CLIENT_ID;
  const publicUIAddress = brand === REEBOK ? window.REEBOK_PUBLIC_UI_ADDRESS : window.ADIDAS_PUBLIC_UI_ADDRESS;
  const pingFederateAddress =
    brand === REEBOK ? window.REEBOK_PING_FEDERATE_ADDRESS : window.ADIDAS_PING_FEDERATE_ADDRESS;
  const adapterId = {
    google: brand === REEBOK ? window.REEBOK_PING_FEDERATE_GOOGLE_ID : window.ADIDAS_PING_FEDERATE_GOOGLE_ID,
    facebook: brand === REEBOK ? window.REEBOK_PING_FEDERATE_FACEBOOK_ID : window.ADIDAS_PING_FEDERATE_FACEBOOK_ID
  };
  const validatorId = brand === REEBOK ? 'reebok' : 'scv';
  const title = brand === REEBOK ? 'Reebok' : 'adidas';

  return {
    clientId,
    publicUIAddress,
    pingFederateAddress,
    adapterId,
    validatorId,
    title
  };
};
