const sharedLocales = [
  { locale: 'en_GB' },
  { locale: 'en_US' },
  { locale: 'de_AT' },
  { locale: 'de_CH', sitePath: 'de' },
  { locale: 'fr_CH', sitePath: 'fr' },
  { locale: 'en_CH', sitePath: 'en' },
  { locale: 'it_CH', sitePath: 'it' },
  { locale: 'cs_CZ' },
  { locale: 'de_DE' },
  { locale: 'da_DK' },
  { locale: 'es_ES' },
  { locale: 'fr_FR' },
  { locale: 'en_FI' },
  { locale: 'el_GR' },
  { locale: 'en_IE' },
  { locale: 'en_NO' },
  { locale: 'no_NO' },
  { locale: 'it_IT' },
  { locale: 'nl_NL' },
  { locale: 'pl_PL' },
  { locale: 'pt_PT' },
  { locale: 'en_PT' },
  { locale: 'sv_SE' },
  { locale: 'sk_SK' },
  { locale: 'en_CA', sitePath: 'en' },
  { locale: 'fr_CA', sitePath: 'fr' },
  { locale: 'en_AU' },
  { locale: 'en_NZ' },
  { locale: 'es_AR' },
  { locale: 'pt_BR' },
  { locale: 'es_CO' },
  { locale: 'es_MX' },
  { locale: 'ru_RU' }
]
const adiLocales = [
  ...sharedLocales,
  { locale: 'fr_BE', sitePath: 'fr' },
  { locale: 'nl_BE', sitePath: 'nl' },
  { locale: 'en_BE', sitePath: 'en' },
  { locale: 'en_DE', sitePath: 'en' },
  { locale: 'gr_GR' },
  { locale: 'en_PH' },
  { locale: 'en_SG' },
  { locale: 'en_TH', sitePath: 'en' },
  { locale: 'th_TH', sitePath: 'th' },
  { locale: 'es_CL' },
  { locale: 'es_PE' },
  { locale: 'id_ID', sitePath: 'id' },
  { locale: 'en_ID', sitePath: 'en' },
  { locale: 'ms_MY' },
  { locale: 'en_MY' },
  { locale: 'vi_VN', sitePath: 'vi' },
  { locale: 'en_VN', sitePath: 'en' },
  { locale: 'en_IN' },
  { locale: 'tr_TR', sitePath: 'tr' },
  { locale: 'en_TR', sitePath: 'en' },
  { locale: 'ko_KR' }
]

const rbkLocales = [
  ...sharedLocales,
  { locale: 'fr_BE' },
  { locale: 'nl_BE' },
  { locale: 'en_BE' },
  { locale: 'en_DE' }
]

export { adiLocales, rbkLocales }
