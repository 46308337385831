export const BRANDS = {
ADIDAS: 'adidas',
REЕBOK: 'reebok',
YEEZY: 'yeezy',
};

export const BRANDS_ABBREVIATION = {
    ADI :'adi',
    RBK : 'rbk',
    YZY : 'yzy',
};