import translate from "./translate"

export const getMenuItems = (brand, locale) => {
  function getTranslatedString(label) {
    return translate({ stringOrKey: label, variables: null, locale, brand })
  }

  return [
    {
      key: 'home.label',
      title: getTranslatedString('home.label'),
      url: 'https://www.yeezysupply.com/'
    },
    {
      key: 'general.label',
      title: getTranslatedString('general.info.label'),
      url: 'https://www.yeezysupply.com/pages/general'
    },
    {
      key: 'contact.label',
      title: getTranslatedString('contact.label'),
      url: 'https://www.yeezysupply.com/pages/contact'
    },
    {
      key: 'privacy.label',
      title: getTranslatedString('privacy.label'),
      url: 'https://www.yeezysupply.com/pages/privacy'
    },
    {
      key: 'terms.label',
      title: getTranslatedString('terms.label'),
      url: 'https://www.yeezysupply.com/pages/terms'
    },
    {
      key: 'archive.label',
      title: getTranslatedString('archive.label'),
      url: 'https://www.yeezysupply.com/archive'
    }
  ]
}
