import React from 'react'
import axios from 'axios'

import { setBrandHeaderAndFooter } from '../../utils/getHeaderFooter'
import {
  brandAbbreviation,
  getBrandByDomain,
  getEnvByDomain,
  getLocale
} from '../../utils'
import { BRANDS_ABBREVIATION } from '../../constants/brands'
import { setCookie } from '../../utils/cookie'
import rbkSvg from '../../utils/icons/reebok2-sprite'
import adiSvg from '../../utils/icons/adidas-sprite'

import './Main.css'
import '../../utils/overrides.css'
import LandingPage from './LandingPage'
import LandingPageYeezy from './LandingPageYeezy'
import Head from './Head'

const getQueryParams = (query, params = []) => {
  const urlParams = new URLSearchParams(query)
  return params.map(p => urlParams.get(p))
}
const ORIGIN = 'sfmc'
class Main extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      unsubscribed: false,
      email: '',
      locale: 'en_US',
      brand: 'adi',
      brandName: 'adidas',
      country: 'US',
      uid: '',
      errors: false,
      alreadyUnsubscribed: false,
      buttonDisabled: true,
      pid: '',
      token: ''
    }

    this.handleQuery = this.handleQuery.bind(this)
    this.handleUnsubscribe = this.handleUnsubscribe.bind(this)
  }

  handleQuery(query) {
    const [lid, country, uid, pid, token] = getQueryParams(query, [
      'lid',
      'country',
      'uid',
      'pid',
      'tkn'
    ])

    const brandName = getBrandByDomain(window.location.hostname)
    const brand = brandAbbreviation[brandName]

    const locale = getLocale(lid)

    this.setState(() => ({
      locale,
      country,
      brand,
      brandName,
      uid,
      pid,
      token
    }))

    axios
      .get(`/api/user-info${query}&brand=${brandName}`, {
        headers: {
          'x-site-locale': locale,
          ...(uid ? { 'x-request-tracing-id': uid } : {})
        }
      })
      .then(({ data }) => {
        if (data.status >= 400) {
          this.setState({ errors: true })

          return false
        }

        if (data.optedOut) {
          this.setState({ alreadyUnsubscribed: true, errors: true })
        } else {
          this.setState(() => ({
            email: data.email
          }))
        }

        this.setState({ buttonDisabled: false })

        return true
      })
      .catch(() => {
        this.setState({ errors: true })
      })
    setBrandHeaderAndFooter(
      {
        brand: brand.toUpperCase(),
        country,
        lang: locale,
        needHeaderFooter: true
      },
      document.getElementById('content')
    )
  }

  handleUnsubscribe() {
    const defaultPayload = {
      country: this.state.country,
      brand: this.state.brandName,
      locale: this.state.locale
    }
    const { uid: acid } = this.state
    const isSfmcFlow = ORIGIN === this.state.pid && this.state.token
    const payload = isSfmcFlow
      ? {
          pid: this.state.pid,
          token: this.state.token,
          ...defaultPayload
        }
      : {
          uid: this.state.uid,
          ...defaultPayload
        }

    axios
      .post('/api/unsubscribe', payload, {
        headers: {
          'x-site-locale': payload.locale,
          ...(acid ? { 'x-request-tracing-id': acid } : {})
        }
      })
      .then(({ data }) => {
        if (data.status === 200) {
          this.setState({ unsubscribed: true })
        } else if (data.status >= 400) {
          this.setState({ errors: true })

          throw new Error('error')
        }
      })
      .catch(error => {
        this.setState({ errors: error })

        throw new Error('error')
      })
  }

  componentDidMount() {
    const query = window.location.search
    if (!query) {
      window.location.href = `https://www.${getBrandByDomain(
        window.location.hostname
      )}.com`
    } else {
      this.handleQuery(query)
    }
    // get rid of annoying popup
    setCookie('UserSignUpAndSave', 2, 1000)
  }

  render() {
    const isYeezy = this.state.brand === BRANDS_ABBREVIATION.YZY
    const { env } = getEnvByDomain()
    const isProd = env === 'production'
    return (
      <div className={`content page-opt-out brand-${this.state.brand}`}>
        <Head {...this.state} isProd={isProd} />
        <div
          style={{
            position: 'absolute',
            width: 0,
            height: 0,
            overflow: 'hidden'
          }}
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html:
              this.state.brand === BRANDS_ABBREVIATION.ADI ? adiSvg : rbkSvg
          }}
        />
        {isYeezy ? (
          <LandingPageYeezy
            onUnsubscribe={this.handleUnsubscribe}
            {...this.state}
          />
        ) : (
          <LandingPage onUnsubscribe={this.handleUnsubscribe} {...this.state} />
        )}
      </div>
    )
  }
}

export default Main
