export default {
  adi: {
    en_UK: 'http://www.adidas.co.uk/help',
    en_US: 'http://www.adidas.com/help',
    en_CH: 'https://www.adidas.ch/en/help',
    de_CH: 'https://www.adidas.ch/de/hilfe',
    fr_CH: 'https://www.adidas.ch/fr/aide',
    it_CH: 'https://www.adidas.ch/it/aiuto',
    cs_CZ: 'https://www.adidas.cz/pomoc',
    da_DK: 'https://www.adidas.dk/hjaelp',
    de_AT: 'https://www.adidas.at/hilfe',
    de_DE: 'https://www.adidas.de/hilfe',
    gr_GR: 'https://www.adidas.gr/%CE%B2%CE%BF%CE%AE%CE%B8%CE%B5%CE%B9%CE%B1',
    el_GR: 'https://www.adidas.gr/%CE%B2%CE%BF%CE%AE%CE%B8%CE%B5%CE%B9%CE%B1',
    en_AU: 'https://www.adidas.com.au/help',
    en_BH: 'https://shop.adidas.bh/en/help/contact.html',
    en_CA: 'https://www.adidas.ca/en/help',
    fr_CA: 'https://www.adidas.ca/fr/aide',
    en_FI: 'https://www.adidas.fi/help',
    en_IE: 'https://www.adidas.ie/help',
    en_IN: 'https://www.adidas.co.in/on/demandware.store/Sites-adidas-AME-Site/en_IN/CustomerService-Show',
    en_NZ: 'https://www.adidas.co.nz/help',
    en_PH: 'https://www.adidas.com.ph/help.html',
    en_PT: 'https://www.adidas.pt/ajuda',
    pt_PT: 'https://www.adidas.pt/ajuda',
    en_SG: 'https://www.adidas.com.sg/help.html',
    en_TH: 'https://www.adidas.co.th/en/contact.html',
    th_TH: 'https://www.adidas.co.th/th/contact.html',
    en_ZA: 'https://shop.adidas.co.za/faq/',
    es_AR: 'https://www.adidas.com.ar/customer-service-landing.html',
    es_CL: 'https://www.adidas.cl/customer-service-landing.html',
    es_CO: 'https://www.adidas.co/customer-service-landing.html',
    es_ES: 'https://www.adidas.es/ayuda',
    es_MX: 'https://www.adidas.mx/customer-service-landing.html',
    es_PE: 'https://www.adidas.pe/customer-service-landing.html',
    fr_BE: 'https://www.adidas.be/help',
    nl_BE: 'https://www.adidas.be/help',
    fr_FR: 'https://www.adidas.fr/aide',
    hu_HU: 'https://www.adidas.hu/on/demandware.store/Sites-adidas-EA-Site/hu_HU/CustomerService-Show',
    id_ID: 'https://www.adidas.co.id/help-topics-general.html',
    en_ID: 'https://www.adidas.co.id/help-topics-general.html',
    it_IT: 'https://www.adidas.it/aiuto',
    ja_JP: 'https://faq.adidas-group.jp/?site_domain=adidas',
    ms_MY: 'https://www.adidas.com.my/ms/help.html',
    en_MY: 'https://www.adidas.com.my/en/help.html',
    nl_NL: 'https://www.adidas.nl/help',
    pl_PL: 'https://www.adidas.pl/pomoc',
    pt_BR: 'https://www.adidas.com.br/on/demandware.store/Sites-adidas-BR-Site/pt_BR/CustomerService-Show',
    ru_RU: 'https://www.adidas.ru/help',
    sk_SK: 'https://www.adidas.sk/pomoc',
    sv_SE: 'https://www.adidas.se/hjalp',
    vi_VN: 'https://www.adidas.com.vn/on/demandware.store/Sites-adidas-AME-Site/vi_VN/CustomerService-Show',
    en_VN: 'https://www.adidas.com.vn/on/demandware.store/Sites-adidas-AME-Site/vi_VN/CustomerService-Show',
    tr_TR: 'https://www.adidas.com.tr/tr/help.html',
    en_TR: 'https://www.adidas.com.tr/en/help.html',
    no_NO: 'https://www.adidas.no/hjelp',
    en_NO: 'https://www.adidas.no/hjelp'
  },
  rbk: {
    en_UK: 'http://www.reebok.co.uk/help',
    en_US: 'http://www.reebok.com/help',
    de_AT: 'https://www.reebok.at/hilfe',
    fr_BE: 'https://www.reebok.be/aide',
    nl_BE: 'https://www.reebok.be/aide',
    hr_HR: '',
    de_CH: 'https://www.reebok.ch/de/hilfe',
    fr_CH: 'https://www.reebok.ch/fr/aide',
    en_CH: 'https://www.reebok.ch/en/help',
    it_CH: 'https://www.reebok.ch/it/aiuto',
    cs_CZ: 'https://www.reebok.cz/pomoc',
    de_DE: 'https://www.reebok.de/hilfe',
    da_DK: 'https://www.reebok.dk/hjaelp',
    es_ES: 'https://www.reebok.es/ayuda',
    fr_FR: 'https://www.reebok.fr/aide',
    en_FI: 'https://www.reebok.fi/help',
    el_GR: 'https://www.reebok.com/gr/help',
    sr_RS: '',
    en_IE: 'https://www.reebok.ie/help',
    en_NO: 'https://www.reebok.com/no/help',
    no_NO: 'https://www.reebok.com/no/help',
    it_IT: 'https://www.reebok.it/aiuto',
    nl_NL: 'https://www.reebok.nl/help',
    pl_PL: 'https://www.reebok.pl/pomoc',
    pt_PT: 'https://www.reebok.com/pt/help',
    en_PT: 'https://www.reebok.com/pt/help',
    sv_SE: 'https://www.reebok.se/hjalp',
    sk_SK: 'https://www.reebok.sk/pomoc',
    tr_TR: 'https://www.reebok.com.tr/online/sikca-sorulan-sorular/1524',
    en_CA: 'https://www.reebok.ca/en/help',
    fr_CA: 'https://www.reebok.ca/fr/aide',
    en_AU: 'https://www.reebok.com.au/',
    en_NZ: 'https://www.reebok.co.nz/help.html',
    en_IN: 'https://shop4reebok.com/#contactus',
    en_ZA: 'https://www.reebok.co.za/help/',
    es_AR: 'https://www.reebok.com.ar/on/demandware.store/Sites-Reebok-AR-Site/es_AR/CustomerService-Show',
    pt_BR: 'https://www.reebok.com.br/on/demandware.store/Sites-Reebok-BR-Site/pt_BR/CustomerService-Show',
    es_CO: 'https://www.reebok.co/on/demandware.store/Sites-Reebok-CO-Site/es_CO/CustomerService-Show',
    es_MX: 'https://www.reebok.mx/on/demandware.store/Sites-Reebok-MX-Site/es_MX/CustomerService-Show',
    ja_JP: 'https://faq.adidas-group.jp/?site_domain=reebok',
    ko_KR: 'http://shop.reebok.co.kr/RPF080301.action',
    en_BH: 'https://www.reebok.ae/en/help/faqs.html',
    ru_RU: 'https://www.reebok.ru/podderzhka',
    uk_UA: 'https://www.reebok.in.ua/contacts.html'
  },
  yzy:{
    en_US: 'https://www.yeezysupply.com/pages/general'
  },
};
