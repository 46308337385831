export const ENVS = {
  staging: {
    env: 'staging',
    subDomain: 'staging.',
    instanaKey: '8aTfSHlIR-ufclRK4TrQ4Q'
  },
  production: {
    env: 'production',
    subDomain: '',
    instanaKey: 'LdvH8yLMRt6IDzbMWnmZKw'
  }
}
