import * as React from 'react'
import ReactDOM from 'react-dom'
import { initializeCrystalHost } from '@crystal/core/state'
import PropTypes from 'prop-types'

import 'theme-orchestrator/css/adidas/tokens/colors.css'
import 'theme-orchestrator/css/adidas/helpers.css'
import 'theme-orchestrator/css/v4.1.9/foundation-adidas.css'

import './CommunicationSettings.css'

import { CommunicationSettings as CommunicationSettingsClient } from '@account/communication-settings-client'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'

window.React = React
window.ReactDOM = ReactDOM

const CommunicationSettings = ({ context, fallback }) => {
  useInitializeCrystal(context)

  return (
    <ErrorBoundary fallback={fallback}>
      <CommunicationSettingsClient context={context} onRedirect={() => {}} />
    </ErrorBoundary>
  )
}

export const useInitializeCrystal = context => {
  React.useEffect(() => {
    initializeCrystalHost({
      host: {
        appId: 'opt-out',
        context
      }
    })
  }, [context])
}

CommunicationSettings.propTypes = {
  fallback: PropTypes.node,
  context: PropTypes.shape({
    env: PropTypes.string,
    brand: PropTypes.string,
    locale: PropTypes.string
  })
}

export default CommunicationSettings
