import React from 'react'
import PropTypes from 'prop-types'
import { GlButton } from '@adl/component-button'
import translate from '../../utils/translate'

const UnsubscriptionBlock = props => (
  <div className={props.className}>
    <h5 className="gl-heading--bold">
      {translate({
        stringOrKey: 'unsubscribe.confirmation.label',
        locale: props.locale,
        brand: props.brand
      })}
    </h5>
    {props.email && (
      <p>
        <strong>
          {translate({
            stringOrKey: 'subscribed.as.label',
            locale: props.locale,
            brand: props.brand
          })}
        </strong>
        <br />
        {props.email}
      </p>
    )}
    <GlButton disabled={props.buttonDisabled} onClick={props.onUnsubscribe}>
      {translate({
        stringOrKey: 'unsubscribe.confirmation.btn',
        locale: props.locale,
        brand: props.brand
      })}
    </GlButton>
  </div>
)

UnsubscriptionBlock.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  onUnsubscribe: PropTypes.func,
  locale: PropTypes.string,
  email: PropTypes.string,
  brand: PropTypes.string,
  buttonDisabled: PropTypes.bool
}

export default UnsubscriptionBlock
