import React from 'react'
import PropTypes from 'prop-types'
import { GlCallout } from '@adl/component-callout'
import { GlButton } from '@adl/component-button'
import { getHomePageUrl } from '../../utils/getPageBrandUrl'
import { BRANDS } from '../../constants'
import translate from '../../utils/translate'
import './ErrMsg.css'

const ErrMsg = props => {
  const { brand, alreadyUnsubscribed, locale, link } = props
  return (
    <GlCallout className="full-width alert-msg" type="very-urgent">
      <>
        <h5 className="gl-heading--black">
          {translate({
            stringOrKey: alreadyUnsubscribed
              ? 'error.already.unsubscribed'
              : 'error.generic',
            locale,
            brand
          })}
        </h5>
        <p>
          {translate({
            stringOrKey: alreadyUnsubscribed
              ? 'already.unsubscribed.successfully.message'
              : 'error.message',
            variables: alreadyUnsubscribed ? link : null,
            locale,
            brand
          })}
        </p>
      </>

      <GlButton
        aria-label="Tertiary"
        href={getHomePageUrl(brand, locale)}
        className={`${brand === BRANDS.YEEZY ? 'back-to-home' : ''}`}
      >
        {translate({
          stringOrKey: 'back.to.home.btn',
          locale,
          brand
        })}
      </GlButton>
    </GlCallout>
  )
}

ErrMsg.propTypes = {
  link: PropTypes.string,
  locale: PropTypes.string,
  brand: PropTypes.string,
  alreadyUnsubscribed: PropTypes.bool
}

export default ErrMsg
