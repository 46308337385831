import React from 'react'
import PropTypes from 'prop-types'
import { GlCallout } from '@adl/component-callout'
import { GlButton } from '@adl/component-button'
import { getHomePageUrl } from '../../utils/getPageBrandUrl'
import { BRANDS } from '../../constants'
import translate from '../../utils/translate'
import './SuccessMsg.css'

const SuccessMsg = props => {
  const { brand, locale, link } = props
  return (
    <GlCallout className="full-width alert-msg">
      <h5 className="gl-heading--black">
        {translate({
          stringOrKey: 'unsubscribed.successfully.message',
          locale,
          brand
        })}
      </h5>
      <p>
        {translate({
          stringOrKey: 'success.message',
          variables: [link],
          locale,
          brand
        })}
      </p>
      <GlButton
        tertiary
        aria-label="Tertiary"
        href={getHomePageUrl(brand, locale)}
        className={`${brand === BRANDS.YEEZY ? 'back-to-home' : ''}`}
      >
        {translate({
          stringOrKey: 'back.to.home.btn',
          locale,
          brand
        })}
      </GlButton>
    </GlCallout>
  )
}

SuccessMsg.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  link: PropTypes.string,
  locale: PropTypes.string,
  brand: PropTypes.string
}

export default SuccessMsg
