// @flow
import { countries, BRANDS_ABBREVIATION, BRANDS } from '../constants'
import { getCountryByLocale, getEnvByDomain } from '.'

/**
 * Computes URLs for DW's Page-Brand.
 * @param {string} countryCode The country code
 * @param {string} brand Campaign's brand - ADI | RBK
 * @param {string} language Campaign's language
 * @returns {Object} { desktop, mobile } The desktop and mobile version of the URLs
 */

const getPageBrandURL = (countryCode, brand, language) => {
  const country = countries[countryCode]

  if (!country || !country.some(({ brands }) => brands.includes(brand))) {
    return {}
  }

  const {
    tld,
    site,
    lang = language
  } = country.find(({ brands }) => brands.includes(brand))
  const domain =
    brand.toLowerCase() === BRANDS_ABBREVIATION.ADI
      ? BRANDS.ADIDAS
      : BRANDS.REЕBOK.replace('r', 'R')

  return {
    desktop: `https://www.${domain.toLowerCase()}.${tld}/on/demandware.store/Sites-${domain}-${site}-Site/${lang}/Page-Brand`,
    mobile: `https://m.${domain.toLowerCase()}.${tld}/on/demandware.store/Sites-${domain}-${site}-Site/${lang}/Page-Brand`
  }
}

const getHomePageUrl = (brand, locale = '') => {
  if (brand === BRANDS.YEEZY) {
    return 'https://www.yeezysupply.com'
  }

  const country = getCountryByLocale(locale)
  return `https://www.${getEnvByDomain().subDomain}${brand}.${
    countries[country]?.[0]?.tld
  }`
}

export { getPageBrandURL, getHomePageUrl }
