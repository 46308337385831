export const countries = {
  AE: [{ brands: ['ADI', 'RBK'], tld: 'ae', site: 'AE' }],
  AU: [{ brands: ['ADI', 'RBK'], tld: 'com.au', site: 'AU' }],
  AR: [{ brands: ['ADI', 'RBK'], tld: 'com.ar', site: 'AR' }],
  AT: [{ brands: ['ADI', 'RBK'], tld: 'at', site: 'AT' }],
  BE: [{ brands: ['ADI', 'RBK'], tld: 'be', site: 'BE' }],
  BR: [{ brands: ['ADI', 'RBK'], tld: 'com.br', site: 'BR' }],
  CA: [{ brands: ['ADI', 'RBK'], tld: 'ca', site: 'CA' }],
  CH: [{ brands: ['ADI', 'RBK'], tld: 'ch', site: 'CH' }],
  CL: [{ brands: ['ADI', 'RBK'], tld: 'cl', site: 'CL' }],
  CO: [{ brands: ['ADI', 'RBK'], tld: 'co', site: 'CO' }],
  CZ: [
    { brands: ['ADI'], tld: 'cz', site: 'CZ' },
    { brands: ['RBK'], tld: 'cz', site: 'MLT', lang: 'en_CZ' }
  ],
  DE: [{ brands: ['ADI', 'RBK'], tld: 'de', site: 'DE' }],
  DK: [{ brands: ['ADI', 'RBK'], tld: 'dk', site: 'DK' }],
  ES: [{ brands: ['ADI', 'RBK'], tld: 'es', site: 'ES' }],
  FI: [{ brands: ['ADI', 'RBK'], tld: 'fi', site: 'FI' }],
  FR: [{ brands: ['ADI', 'RBK'], tld: 'fr', site: 'FR' }],
  GB: [{ brands: ['ADI', 'RBK'], tld: 'co.uk', site: 'GB' }],
  GR: [
    { brands: ['ADI'], tld: 'gr', site: 'MLT' },
    { brands: ['RBK'], tld: 'com', site: 'MLT', lang: 'en_GR' }
  ],
  ID: [{ brands: ['ADI', 'RBK'], tld: 'co.id', site: 'AME' }],
  IE: [{ brands: ['ADI', 'RBK'], tld: 'ie', site: 'IE' }],
  IN: [{ brands: ['ADI', 'RBK'], tld: 'co.in', site: 'AME' }],
  IT: [{ brands: ['ADI', 'RBK'], tld: 'it', site: 'IT' }],
  HU: [{ brands: ['ADI', 'RBK'], tld: 'hu', site: 'EA' }],
  MX: [{ brands: ['ADI', 'RBK'], tld: 'mx', site: 'MX' }],
  MY: [{ brands: ['ADI', 'RBK'], tld: 'com.my', site: 'MY' }],
  NL: [{ brands: ['ADI', 'RBK'], tld: 'nl', site: 'NL' }],
  NO: [
    { brands: ['ADI'], tld: 'no', site: 'NO' },
    { brands: ['RBK'], tld: 'com', site: 'NO', lang: 'en_NO' }
  ],
  NZ: [{ brands: ['ADI', 'RBK'], tld: 'co.nz', site: 'NZ' }],
  PE: [{ brands: ['ADI', 'RBK'], tld: 'pe', site: 'PE' }],
  PH: [{ brands: ['ADI', 'RBK'], tld: 'com.ph', site: 'PH' }],
  PL: [{ brands: ['ADI', 'RBK'], tld: 'pl', site: 'PL' }],
  PT: [
    { brands: ['ADI'], tld: 'pt', site: 'MLT' },
    { brands: ['RBK'], tld: 'com', site: 'MLT', lang: 'en_PT' }
  ],
  RU: [{ brands: ['ADI', 'RBK'], tld: 'ru', site: 'RU' }],
  SE: [{ brands: ['ADI', 'RBK'], tld: 'se', site: 'SE' }],
  SG: [{ brands: ['ADI', 'RBK'], tld: 'com.sg', site: 'SG' }],
  SK: [
    { brands: ['ADI'], tld: 'sk', site: 'SK' },
    { brands: ['RBK'], tld: 'sk', site: 'MLT', lang: 'en_SK' }
  ],
  TH: [{ brands: ['ADI', 'RBK'], tld: 'co.th', site: 'TH' }],
  TR: [{ brands: ['ADI', 'RBK'], tld: 'com.tr', site: 'TR', lang: 'tr_TR' }],
  US: [{ brands: ['ADI', 'RBK'], tld: 'com', site: 'US', lang: 'en_US' }],
  VN: [{ brands: ['ADI', 'RBK'], tld: 'com.vn', site: 'AME' }],
  ZA: [{ brands: ['ADI', 'RBK'], tld: 'co.za', site: 'EA' }],
  KR: [{ brands: ['ADI'], tld: 'co.kr', site: 'KO' }]
}
