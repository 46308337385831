import { BRANDS, ENVS } from '../constants'
import { getLinks } from './getAccountLinks'

export const getAccountLinks = () => ({
  adi: {
    staging: {
      ...getLinks(BRANDS.ADIDAS, ENVS.staging),
      en_AE: 'https://www.staging.adidas.ae/en/account-preference'
    },
    production: {
      ...getLinks(BRANDS.ADIDAS, ENVS.production),
      en_AE: 'https://www.adidas.ae/en/account-preference'
    }
  },
  rbk: {
    staging: {
      ...getLinks(BRANDS.REЕBOK, ENVS.staging),
      tr_TR:
        'https://www.staging.reebok.com.tr/uye?ReturnUrl=/online/sikca-sorulan-sorular/1524'
    },
    production: {
      ...getLinks(BRANDS.REЕBOK, ENVS.production),
      tr_TR:
        'https://www.reebok.com.tr/uye?ReturnUrl=/online/sikca-sorulan-sorular/1524'
    }
  }
})
