import React from 'react'
import PropTypes from 'prop-types'
import { GlButton } from '@adl/component-button'
import translate from '../../utils/translate'

const UpdateSubscriptionBlock = props => (
  <div className={props.className}>
    <h5 className="gl-heading--bold">
      {translate({
        stringOrKey: 'update.subscription.label',
        locale: props.locale,
        brand: props.brand
      })}
    </h5>
    <GlButton href={props.link}>
      {translate({
        stringOrKey: 'update.subscription.btn',
        locale: props.locale,
        brand: props.brand
      })}
    </GlButton>
  </div>
)

UpdateSubscriptionBlock.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  locale: PropTypes.string,
  link: PropTypes.string,
  brand: PropTypes.string
}

export default UpdateSubscriptionBlock
