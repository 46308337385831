import React from 'react'
import PropTypes from 'prop-types'
import { BRANDS_ABBREVIATION } from '../../constants'
import translate from '../../utils/translate'
import './Header.css'

const Header = props => (
  <>
    {window.noHeader ? (
      <div className="substitude-header">
        {props.brand === BRANDS_ABBREVIATION.ADI ? (
          <a className="substitude-header__link" href="http://www.adidas.com">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="69"
              height="76"
              viewBox="0 0 42 28"
            >
              <title>iconPerformanceLogo</title>
              <path d="M38.424 28.014c-1.962 0-3.139-1.009-3.223-2.41h1.85c0 0.448 0.28 1.093 1.485 1.121 0.785 0 1.149-0.476 1.149-0.841-0.028-0.533-0.729-0.589-1.485-0.701-0.757-0.14-1.373-0.252-1.85-0.504-0.561-0.308-0.981-0.953-0.981-1.71 0-1.261 1.121-2.27 2.943-2.27 1.766 0 2.887 0.953 2.999 2.326h-1.766c-0.028-0.364-0.112-0.981-1.149-0.981-0.701 0-1.177 0.168-1.205 0.645 0 0.729 1.457 0.673 2.607 0.981 1.093 0.28 1.794 0.953 1.794 1.906 0 1.766-1.429 2.438-3.167 2.438v0zM4.483 16.298l6.166-3.559 3.251 5.633h-8.212l-1.205-2.074zM18.244 27.846h-1.738v-7.063h1.738v7.063zM1.68 24.342c0 1.093 0.925 1.99 2.046 1.99s2.046-0.897 2.046-1.99-0.925-2.046-2.046-2.046c-1.121 0-2.046 0.953-2.046 2.046v0zM3.67 28.014c-1.99 0-3.672-1.654-3.672-3.672 0-2.046 1.682-3.644 3.672-3.644 0.757 0 1.485 0.196 2.102 0.617v-0.533h1.738v7.091h-1.738v-0.476c-0.589 0.392-1.317 0.617-2.102 0.617v0zM27.269 18.372l-8.548-14.855 6.138-3.531 10.65 18.386h-8.24zM9.808 24.342c0 1.093 0.925 1.99 2.046 1.99 1.093 0 2.046-0.897 2.046-1.99s-0.953-2.046-2.046-2.046c-1.121 0-2.046 0.953-2.046 2.046v0zM11.798 28.014c-2.018 0-3.7-1.654-3.7-3.672s1.682-3.644 3.7-3.644c0.757 0 1.485 0.224 2.102 0.645v-2.971h1.738v9.501h-1.738v-0.476c-0.617 0.392-1.317 0.617-2.102 0.617v0zM11.658 9.964l6.166-3.531 6.895 11.94h-6.474v1.738h-1.738v-1.738l-4.849-8.408zM28.755 24.342c0 1.093 0.925 1.99 2.018 1.99s2.018-0.897 2.018-1.99c0-1.093-0.925-2.046-2.018-2.046s-2.018 0.953-2.018 2.046v0zM30.717 28.014c-2.018 0-3.672-1.654-3.672-3.672s1.654-3.644 3.672-3.644c0.757 0 1.485 0.224 2.074 0.645v-0.561h1.766v7.091h-1.766v-0.476c-0.589 0.392-1.289 0.617-2.074 0.617v0zM20.655 24.342c0 1.093 0.925 1.99 2.018 1.99 1.121 0 2.046-0.897 2.046-1.99s-0.925-2.046-2.046-2.046c-1.093 0-2.018 0.953-2.018 2.046v0zM24.719 18.372h1.738v9.473h-1.738v-0.476c-0.589 0.392-1.317 0.645-2.102 0.645-1.99 0-3.672-1.682-3.672-3.672 0-2.046 1.682-3.644 3.672-3.644 0.785 0 1.485 0.196 2.102 0.617v-2.943z" />
            </svg>
          </a>
        ) : (
          <a
            className="substitude-header__link-rbk"
            href="https://www.reebok.com"
          >
            <img alt="reebok-logo" src="/images/reebok-logo-white.svg" />
          </a>
        )}
      </div>
    ) : (
      ''
    )}
    {!props.unsubscribed ? (
      <div
        className="hero"
        style={{
          backgroundImage: `url("/images/optout-bg-${props.brand}.jpg")`
        }}
      >
        <div className="inner-content">
          <div className="container row">
            <div className="col-s-12">
              <h4 className="gl-heading--bold opt-out-title">
                {translate({
                  stringOrKey: 'missing.mark.label',
                  locale: props.locale,
                  brand: props.brand
                })}
              </h4>
            </div>
          </div>
        </div>
      </div>
    ) : (
      ''
    )}
  </>
)

Header.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  unsubscribed: PropTypes.bool,
  locale: PropTypes.string,
  brand: PropTypes.string
}

export default Header
