import axios from 'axios';
import { removeElementByClassName } from './removeElement';
import { checkMobileBasedOnSize } from './device';
import { loadHeadElements, loadScripts } from './pageUtils';
import getBrandAddresses from './getBrandAddresses';
import { getPageBrandURL } from './getPageBrandUrl';

// const pageBrandURL = 'https://www.adidas.com/on/demandware.store/Sites-adidas-US-Site/en_US/Page-Brand';

/**
 * Parses the Page Brand HTML, identifies the script and non-script elements
 * and add them into the page in parallel.
 *
 * @param {string} [pageBrandHTML=""] A text with Page Brand HTML
 * @returns {Promise} Returns a Promise.all resolution
 */
const transformHead = (pageBrandHTML = '') =>
  new Promise((resolve) => {
    const [ , head ] = pageBrandHTML.match(/<head[^>]*>((.|[\n\r])*)<\/head>/im);
    const headFragment = document.createRange().createContextualFragment(head);

    const [ firstHeadEntry ] = document.head.children;

    // Remove <meta name="robots"> tags
    const elementsToLoad = Array.from(headFragment.querySelectorAll(':not(script)'))
    .filter(el => !(el.tagName.toLowerCase() === 'meta' && el.getAttribute('name') === 'robots'));
    
    const promises = [ loadHeadElements(elementsToLoad, firstHeadEntry) ];

    promises.push(loadScripts(headFragment.querySelectorAll('script')));

    return Promise.all(promises).then(resolve);
  });

/**
 * Injects Page Brand body HTML into current's `document.body`.
 *
 * @param {string} [pageBrandHTML=""] A text with Page Brand HTML
 * @param {string} placeholderId The identifier of the div node where
 *  the campaign HTML should be rendered.
 */
const transformBody = (pageBrandHTML = '', placeholderId) => {
  const [ fullBodyHTML, innerBodyHTML ] = pageBrandHTML.match(/<body[^>]*>((.|[\n\r])*)<\/body>/im);
  const bodyFragment = document.createRange().createContextualFragment(innerBodyHTML);
  const scripts = bodyFragment.querySelectorAll('script');

  // When header and footer are to be shown,
  // put campaign content placeholder as a nested element

  document.body.insertAdjacentHTML(
    'afterbegin',
    innerBodyHTML.replace(/<!-- PAGE_CONTENT -->/, `<div id="${ placeholderId }"></div>`)
  );

  // Inherit page brand's CSS classes for <body> element
  const currentBodyClasses = document.body.className;
  const classesToAdd = new DOMParser().parseFromString(fullBodyHTML, 'text/html').body.className;

  document.body.className = `${ currentBodyClasses } ${ classesToAdd }`;
  loadScripts(scripts);
};

/**
 * returns the protocol + domain part of a given url
 * @param {string} url full url starting with http or https
 * @returns {string} the protocol + domain part of the url
 */
const getDomainUrl = (url) => typeof url === 'string' ? /^(https?:\/\/[^/]*)/.exec(url)[0] : '';

/**
 * Adds a base url to all relative links a[href] found on the document
 * @param {string} baseUrl url to be prepended on relative
 * links present on the document
 * @returns {void}
 */
const addBaseForRelativeUrls = (baseUrl) => {
  document.querySelectorAll('a[href]:not([href^=javascript])').forEach((link) => {
    if (!link.getAttribute('href').startsWith('http')) {
      link.href = `${ baseUrl }${ link.getAttribute('href') }`;
    }
  });
};

/**
 * Calls `transformBody` to inject Page Brand's body markup into current's `document.body`.
 * Calls `addBaseForRelativeUrls` to define a base url to all relative links a[href] found on the document.
 * Sets the `document`'s title based on the campaign brand.
 *
 * @param {string} pageBrandHTML A text with Page Brand HTML
 * @param {Element} campaignRootEl The element where the campaign is rendered
 * @param {string} url full url starting with http or https
 * @param {string} brand The brand of the campaign
 */
const reconcilePageBrand = (pageBrandHTML, campaignRootEl, url, brand) => {
  const placeholderId = 'campaign-within-page-brand';
  const { title } = getBrandAddresses(brand);

  transformBody(pageBrandHTML, placeholderId);
  const placeholder = document.getElementById(placeholderId);

  placeholder.parentNode.replaceChild(campaignRootEl, placeholder);
  addBaseForRelativeUrls(getDomainUrl(url));
  document.title = title;

  removeElementByClassName([
    'footer-newsletter',
    'footer_newsletter',
    'footer-newsletter-gdpr',
    'footer-actions-container',
    'ui-widget-overlay',
    'signUpOverlay'
  ]);
};

/**
 * Fetches the Page Brand HTML, parses it and transforms it accordingly.
 * If the static Page Brand's URLs configuration does not contain an URL
 * for the provided combination of country and brand then there's no header & footer available.
 * Otherwise use `needHeaderFooter` to determine if the header & footer should be displayed or not.
 *
 * @param {Object} {
 *     brand = "ADI",
 *     country = "GB",
 *     needHeaderFooter = false
 *   }={} A campaign
 * @param {Element} campaignRootEl The element where the campaign is rendered
 * @returns {Promise} Axios get action and related promise
 */
const setBrandHeaderAndFooter = ({ brand = 'ADI', country = 'GB', lang = 'en_UK', needHeaderFooter = false } = {}, campaignRootEl) => {
  const breakpoint = checkMobileBasedOnSize() ? 'mobile' : 'desktop';
  const url = getPageBrandURL(country, brand, lang)[breakpoint];

  if (!url) {
    window.noHeader = true;
  }

  const showHeaderFooter = !url ? false : needHeaderFooter;

  return !showHeaderFooter
    ? Promise.resolve()
    : axios
        .get(url)
        .then(({ data }) => transformHead(data).then(() => reconcilePageBrand(data, campaignRootEl, url, brand)));
};

export { setBrandHeaderAndFooter, getDomainUrl, transformHead };
