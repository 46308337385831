const onScriptLoaded = (script, resolve, reject) => {
  if (script.readyState) {
    // IE
    script.onreadystatechange = function() {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        resolve();
      }
    };
  } else {
    // trigger fulfilled state when stylesheet is ready
    script.onload = resolve;

    // trigger rejected state when stylesheet is not found
    script.onerror = reject;
  }
};

const loadScripts = (scripts) =>
  new Promise((resolve) => {
    const loadScript = (scripts, index) => {
      if (index < scripts.length) {
        const newScript = document.createElement('script');

        [ ...scripts[index].attributes ].forEach(({ name, value }) => newScript.setAttribute(name, value));

        if (scripts[index].text) {
          newScript.text = scripts[index].text;
        }

        if (newScript.src) {
          onScriptLoaded(
            newScript,
            () => loadScript(scripts, index + 1),
            () => {
              loadScript(scripts, index + 1);
            }
          );
          document.head.appendChild(newScript);
        }
        if (!newScript.src) {
          try {
            document.head.appendChild(newScript);
          } finally {
            loadScript(scripts, index + 1);
          }
        }
      } else {
        resolve();
      }
    };

    loadScript(scripts, 0);
  });

const loadHeadElements = (elements, reference) =>
  new Promise((resolve, reject) => {
    elements.forEach((e) => {
      onScriptLoaded(e, resolve, reject);
      document.head.insertBefore(e, reference);
    });
  });

export { onScriptLoaded, loadScripts, loadHeadElements };
