import * as React from 'react'
import PropTypes from 'prop-types'
import { isProduction } from '../../utils'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    const isProd = isProduction()
    this.state = { hasError: false, isProd }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    const { isProd } = this.state
    if (!isProd) {
      // eslint-disable-next-line no-console
      console.error(error, info?.componentStack)
    }
  }

  render() {
    const { children, fallback } = this.props

    if (this.state.hasError) {
      return fallback ? <React.Fragment>{fallback}</React.Fragment> : null
    }

    return <React.Fragment>{children}</React.Fragment>
  }
}

ErrorBoundary.propTypes = {
  fallback: PropTypes.node,
  children: PropTypes.node.isRequired
}
