import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { GlButton } from '@adl/component-button'
import SuccessMsg from '../SuccessMsg/SuccessMsg'
import helpLinks from '../../utils/helpPages'
import ErrMsg from '../ErrMsg/ErrMsg'
import Menu from '../MenuYeezy/Menu'
import translate from '../../utils/translate'
import './LandingPageYeezy.css'

const LandingPageYeezy = props => {
  const {
    brand,
    brandName,
    locale,
    buttonDisabled,
    alreadyUnsubscribed,
    unsubscribed,
    errors,
    onUnsubscribe
  } = props

  const [content, hideContent] = useState(false)

  function handleMenu(hidePage) {
    hideContent(hidePage)
  }

  return (
    <div className="gl-app">
      <Menu handleMenu={handleMenu} brand={brandName} locale={locale} />
      <div className={`item-header ${content ? 'hidden' : ''}`}>
        {errors && (
          <ErrMsg
            link={helpLinks[brand][locale]}
            locale={locale}
            alreadyUnsubscribed={alreadyUnsubscribed}
            brand={brandName}
            errors={errors}
          />
        )}
        {!errors && (
          <>
            {unsubscribed ? (
              <div className="row">
                <div className="col-s-12">
                  <SuccessMsg
                    unsubscribed={unsubscribed}
                    link={helpLinks[brand][locale]}
                    locale={locale}
                    brand={brandName}
                  />
                </div>
              </div>
            ) : (
              <div className="opt-out-block-yzy">
                <div className="col-m-7">
                  <h5 className="gl-heading--bold">
                    {translate({
                      stringOrKey: 'unsubscribe.confirmation.label',
                      locale,
                      brand
                    })}
                  </h5>
                  <GlButton
                    className="button-yzy"
                    disabled={buttonDisabled}
                    onClick={onUnsubscribe}
                  >
                    <div>
                      {translate({
                        stringOrKey: 'unsubscribe.confirmation.btn',
                        locale,
                        brand
                      })}
                    </div>
                  </GlButton>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
LandingPageYeezy.propTypes = {
  brand: PropTypes.string,
  brandName: PropTypes.string,
  locale: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  alreadyUnsubscribed: PropTypes.bool,
  unsubscribed: PropTypes.bool,
  errors: PropTypes.bool,
  onUnsubscribe: PropTypes.func
}

export default LandingPageYeezy
