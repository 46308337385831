import {
  ENVS,
  BRANDS_ABBREVIATION,
  BRANDS,
  countries,
  rbkLocales,
  adiLocales
} from '../constants'

const getEnvByDomain = (domain = window.location.hostname) =>
  domain.includes('stg') || domain.includes('localhost')
    ? ENVS.staging
    : ENVS.production

const isProduction = () => {
  const { env } = getEnvByDomain()
  return env === 'production'
}

const createProviderContext = (locale, brand) => {
  const { env } = getEnvByDomain()
  return {
    env,
    brand,
    locale
  }
}

const createMFContext = (locale, brand) => {
  const { env } = getEnvByDomain()
  return {
    env: env === ENVS.production.env ? 'prod' : 'stg',
    brand,
    locale
  }
}

const getTldByLocale = locale => getTld(countries[getCountryByLocale(locale)])

const getTld = countryDetails => {
  const brand = getBrandByDomain()
  if (countryDetails.length > 1) {
    const brandName = brandAbbreviation[brand].toUpperCase()
    const detailByBrand = countryDetails.find(
      detail => detail.brands[0] === brandName
    )
    return detailByBrand?.tld
  }
  return countryDetails[0].tld
}

const getLocale = (lid = '') => {
  const split = lid?.replace('-', '_').split('_')
  if (split?.length !== 2) {
    throw new Error(`'Invalid locale : ${lid}`)
  }
  return [split[0].toLowerCase(), split[1].toUpperCase()].join('_')
}

const getBrandByDomain = (domain = window.location.hostname) => {
  if (domain.includes(BRANDS.YEEZY)) {
    return BRANDS.YEEZY
  }

  return domain.includes(BRANDS.REЕBOK) ? BRANDS.REЕBOK : BRANDS.ADIDAS
}

const getCountryByLocale = locale => locale?.split('_')?.[1]?.toUpperCase()

const brandLocales = {
  [BRANDS.REЕBOK]: rbkLocales,
  [BRANDS.ADIDAS]: adiLocales
}
const brandAbbreviation = {
  [BRANDS.REЕBOK]: BRANDS_ABBREVIATION.RBK,
  [BRANDS.ADIDAS]: BRANDS_ABBREVIATION.ADI,
  [BRANDS.YEEZY]: BRANDS_ABBREVIATION.YZY
}
const getBrandLocales = brand => brandLocales[brand]

export {
  getLocale,
  getBrandByDomain,
  getCountryByLocale,
  getEnvByDomain,
  getTldByLocale,
  getBrandLocales,
  brandAbbreviation,
  isProduction,
  createProviderContext,
  createMFContext
}
