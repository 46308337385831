import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { BRANDS_ABBREVIATION, ENVS } from '../../constants'

import { getInstanaScript } from '../../utils/instanaScript'

const getStyles = brand => {
  switch (brand) {
    case BRANDS_ABBREVIATION.RBK:
      return '/glass/assets/css/foundation-reebok2.css'
    case BRANDS_ABBREVIATION.YZY:
      return '/glass/assets/css/foundation-yeezy.css'
    default:
      return '/glass/assets/css/foundation-adidas.css'
  }
}

const getFavicon = brand => {
  switch (brand) {
    case BRANDS_ABBREVIATION.YZY:
      return 'favicon-yeezy.png'
    case BRANDS_ABBREVIATION.RBK:
      return 'favicon-reebok.ico'
    case BRANDS_ABBREVIATION.ADI:
    default:
      return 'favicon.ico'
  }
}

const Head = props => (
  <Helmet>
    <html
      dir={props.locale === 'ar_AE' ? 'rtl' : 'ltr'}
      lang={props.locale.split('_')[0]}
    />
    <link type="text/css" rel="stylesheet" href={getStyles(props.brand)} />
    <link rel="icon" href={getFavicon(props.brand)} type="image/x-icon" />
    <title>{props.brandName}</title>
    <script type="text/javascript">
      {getInstanaScript(
        props.isProd ? ENVS.production.instanaKey : ENVS.staging.instanaKey
      )}
    </script>
    <script
      defer
      crossOrigin="anonymous"
      src="https://eum.instana.io/eum.min.js"
    />
  </Helmet>
)

Head.propTypes = {
  brand: PropTypes.string,
  brandName: PropTypes.string,
  locale: PropTypes.string,
  isProd: PropTypes.bool
}

export default Head
