import translationsAdi from '../translations'
import translationsRbk from '../translations-rbk'
import translationsYzy from '../translations-yzy'

function splitValue(string) {
  const results = []
  let braceLevel = 0
  let value = ''

  for (let i = 0; i < string.length; i++) {
    const ch = string[i]
    const braceDelta = { '{': 1, '}': -1 }[ch] || 0
    const braceLevelNext = braceLevel + braceDelta

    if (braceLevel === 0 && braceLevelNext === 1) {
      /* end of string */
      results.push(value)
      value = ''
    } else if (braceLevel === 1 && braceLevelNext === 0) {
      /* end of FormatElement */
      results.push({
        type: 'FormatElement',
        value
      })
      value = ''
    } else {
      value = value + ch
    }

    braceLevel = braceLevelNext
  }

  results.push(value)

  return results
}

function parseChoice(string) {
  const [n, opCode, translation] = string.match(/([0-9]+)([#<])(.*)/)
  return {
    opCode,
    // eslint-disable-next-line radix
    n: parseInt(n, 10),
    translation: parseMessageFormat(translation)
  }
}

function parseFormatElement(string) {
  const match = string.match(/^([-a-zA-Z0-9]+)(?:,([a-z]+)(?:,(.*))?)?/)
  const result = {}
  // eslint-disable-next-line prefer-destructuring
  result.variable = match[1]

  if (match[2]) {
    const [formatType, style] = match.slice(1)

    switch (formatType) {
      case 'choice':
        result.choices = style.split('|').map(parseChoice)
        break
      default:
        throw new Error(
          `Unknown operation ${formatType} in translation string ${string}.`
        )
    }
  }
  return result
}

// Given a string, return a JSON representation of the syntax of the
//  translation.
//
// The syntax objects can be:
//
// - An array of recursive syntax objects
// - A literal string
// - A FormatElement referencing a variable, a the optional recursive choices
//
function parseMessageFormat(string) {
  return splitValue(string).map(item => {
    if (typeof item === 'string') {
      return item
    }
    return parseFormatElement(item.value)
  })
}

/* Compile translations into functions to fill in the FormatElements */

function compileFormatElement(variable, choices) {
  const compiledChoices =
    choices &&
    choices.map(choice =>
      Object.assign({}, choice, {
        translation: compileMessageFormat(choice.translation)
      })
    )

  return input => {
    if (!compiledChoices) {
      return [input[variable]]
    }
    const value = input[variable]

    if (typeof value !== 'number') {
      throw new Error(`${variable} should be a number, but got ${value}`)
    }

    const choice = compiledChoices.find(
      choice =>
        (choice.opCode === '#' && choice.n === value) ||
        (choice.opCode === '<' && choice.n < value),
      compiledChoices
    )

    if (!choice) {
      throw new Error(`Couldn't find choice`)
    }

    return choice.translation(input)
  }
}

function compileMessageFormat(parsed) {
  if (typeof parsed === 'string') {
    return () => [parsed]
  } else if (Array.isArray(parsed)) {
    // parsed is an array with different chunks of the translation
    // template. We can compile each chunk separately, and then we'll
    // join the result of all the subtranslations.
    const compiledSubtranslations = parsed.map(compileMessageFormat)
    return input =>
      compiledSubtranslations
        .map(f => f(input))
        .reduce((l1, l2) => l1.concat(l2), [])
  } else if (typeof parsed === 'object') {
    const { variable, choices } = parsed
    return compileFormatElement(variable, choices)
  }
  throw new Error(`Unknown form`)
}

function translateString(string, args) {
  const parsed = parseMessageFormat(string)
  const compiled = compileMessageFormat(parsed)
  const parts = compiled(args)
  return parts.join('')
}

const translate = ({ stringOrKey, variables, locale, brand }) => {
  const translations =
    {
      rbk: translationsRbk,
      adi: translationsAdi,
      yeezy: translationsYzy
    }[brand] || translationsAdi

  const key =
    typeof stringOrKey !== 'undefined'
      ? stringOrKey.toString().trim()
      : stringOrKey
  const availableTranslation = translations[locale] || translations.en_US

  // system translation
  let translatedString = availableTranslation[key] || null

  if (!Array.isArray(variables)) {
    translatedString = translateString(translatedString, [variables])
  }
  translatedString = translateString(translatedString, variables)
  // usually the String itself will return if not matched translation key is found. So for single words you do not
  // need to add translations for English
  return translatedString !== null ? translatedString : stringOrKey
}

export default translate
