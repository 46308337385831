Element.prototype.remove = function () {
  this.parentElement.removeChild(this)
}

// eslint-disable-next-line
NodeList.prototype.remove = HTMLCollection.prototype.remove = function () {
  for (let i = this.length - 1; i >= 0; i--) {
    if (this[i] && this[i].parentElement) {
      this[i].parentElement.removeChild(this[i])
    }
  }
}

const removeElementById = id => {
  const el = document.getElementById(id)

  el.remove()
}

const removeElementByClassName = classNames => {
  if (Array.isArray(classNames)) {
    for (const className of classNames) {
      const el = document.getElementsByClassName(className)
      el.remove()
    }
  } else {
    const el = document.getElementsByClassName(classNames)

    el.remove()
  }
}

export { removeElementById, removeElementByClassName }
