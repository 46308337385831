import * as React from 'react'
import { GlLink } from '@adl/component-link'
import PropTypes from 'prop-types'
import { getAccountLinks } from '../../utils/accountPages'
import UnsubscriptionBlock from '../UnsubscriptionBlock/UnsubscriptionBlock'
import UpdateSubscriptionBlock from '../UpdateSubscriptionBlock/UpdateSubscriptionBlock'
import SuccessMsg from '../SuccessMsg/SuccessMsg'
import { BRANDS_ABBREVIATION } from '../../constants'
import translate from '../../utils/translate'
import helpLinks from '../../utils/helpPages'

const UnsubscribeBlock = ({
  unsubscribed,
  brand,
  locale,
  brandName,
  env,
  buttonDisabled,
  onUnsubscribe,
  email,
  country
}) => (
  <>
    {unsubscribed ? (
      <div className="row">
        <div className="col-s-12">
          <SuccessMsg
            unsubscribed={unsubscribed}
            link={helpLinks[brand][locale]}
            locale={locale}
            brand={brandName}
          />
        </div>
      </div>
    ) : (
      <>
        <div className="row">
          <div className="col-s-6 left">
            <UnsubscriptionBlock
              className="opt-out-block"
              email={email}
              locale={locale}
              brand={brand}
              buttonDisabled={buttonDisabled}
              onUnsubscribe={onUnsubscribe}
            />
          </div>
          <div className="col-s-6 right">
            <UpdateSubscriptionBlock
              className="opt-out-block"
              link={getAccountLinks()[brand][env][locale]}
              locale={locale}
              brand={brand}
            />
          </div>
        </div>
        {brand === BRANDS_ABBREVIATION.ADI &&
        (country === 'FI' || country === 'ZA') ? (
          <div className="row">
            <div className="col-s-12">
              <p className="info-text">
                {translate({
                  stringOrKey: 'runtastic.message',
                  locale,
                  brand
                })}
                <GlLink href="https://www.adidas.com/blog/398752">
                  {translate({
                    stringOrKey: 'info.label',
                    locale,
                    brand
                  })}
                </GlLink>
              </p>
            </div>
          </div>
        ) : (
          ''
        )}
      </>
    )}
  </>
)

UnsubscribeBlock.propTypes = {
  brand: PropTypes.string,
  brandName: PropTypes.string,
  locale: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  country: PropTypes.string,
  email: PropTypes.string,
  unsubscribed: PropTypes.bool,
  errors: PropTypes.bool,
  onUnsubscribe: PropTypes.func,
  env: PropTypes.string
}

export default UnsubscribeBlock
