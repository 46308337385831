import React from 'react'
import PropTypes from 'prop-types'
import { FeatureFlagsProvider, useFeatureFlags } from '@sole/feature-flags'

import Header from '../Header/Header'
import helpLinks from '../../utils/helpPages'
import ErrMsg from '../ErrMsg/ErrMsg'
import UnsubscribeBlock from '../UnsubscribeBlock/UnsubscribeBlock'

import {
  createProviderContext,
  createMFContext,
  getEnvByDomain
} from '../../utils'
import CommunicationSettings from '../CommunicationSettings/CommunicationSettings'

const LandingPage = props => {
  const {
    brand,
    brandName,
    locale,
    alreadyUnsubscribed,
    unsubscribed,
    errors
  } = props

  const featureFlags = useFeatureFlags()
  const hasFeatureFlagsLoaded = Object.keys(featureFlags).length > 0
  const hasErrors = featureFlags?.ACCOUNT_COMMUNICATIONS_SETTINGS_ENABLED
    ? errors && alreadyUnsubscribed === false
    : !!errors

  const { env } = getEnvByDomain()
  return (
    <>
      {hasErrors && (
        <ErrMsg
          link={helpLinks[brand][locale]}
          locale={locale}
          alreadyUnsubscribed={alreadyUnsubscribed}
          brand={brandName}
          errors={errors}
        />
      )}
      {!hasErrors && (
        <>
          <Header unsubscribed={unsubscribed} brand={brand} locale={locale} />
          <div className="inner-content">
            <div className="container">
              {hasFeatureFlagsLoaded ? (
                featureFlags?.ACCOUNT_COMMUNICATIONS_SETTINGS_ENABLED ? (
                  <CommunicationSettings
                    context={createMFContext(locale, brandName)}
                    fallback={<UnsubscribeBlock {...props} env={env} />}
                  />
                ) : (
                  <UnsubscribeBlock {...props} env={env} />
                )
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  )
}

LandingPage.propTypes = {
  brand: PropTypes.string,
  brandName: PropTypes.string,
  locale: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  country: PropTypes.string,
  email: PropTypes.string,
  alreadyUnsubscribed: PropTypes.bool,
  unsubscribed: PropTypes.bool,
  errors: PropTypes.bool,
  onUnsubscribe: PropTypes.func
}

const LandingAppWrapper = props => {
  const { brandName, locale } = props
  const context = createProviderContext(locale, brandName)
  return (
    <FeatureFlagsProvider context={context} projectName={'glass'}>
      <LandingPage {...props} />
    </FeatureFlagsProvider>
  )
}

LandingAppWrapper.propTypes = {
  brand: PropTypes.string,
  brandName: PropTypes.string,
  locale: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  country: PropTypes.string,
  email: PropTypes.string,
  alreadyUnsubscribed: PropTypes.bool,
  unsubscribed: PropTypes.bool,
  errors: PropTypes.bool,
  onUnsubscribe: PropTypes.func
}

export default LandingAppWrapper
