// @flow

import de_DE from './de_DE.json';
import en_US from './en_US.json';
import en_GB from './en_GB.json';
import es_ES from './es_ES.json';
import fr_FR from './fr_FR.json';
import it_IT from './it_IT.json';
import it_CH from './it_CH.json';
import nl_NL from './nl_NL.json';
import cs_CZ from './cs_CZ.json';
import sk_SK from './sk_SK.json';
import ja_JP from './ja_JP.json';
import jp_JP from './jp_JP.json';
import ko_KR from './ko_KR.json';
import pt_PT from './pt_PT.json';
import pt_BR from './pt_BR.json';
import ru_RU from './ru_RU.json';
import pl_PL from './pl_PL.json';
import hu_HU from './hu_HU.json';
import sv_SE from './sv_SE.json';
import da_DK from './da_DK.json';
import tr_TR from './tr_TR.json';
import th_TH from './th_TH.json';
import ms_MY from './ms_MY.json';
import zh_CN from './zh_CN.json';
import zh_HK from './zh_HK.json';
import zh_TW from './zh_TW.json';
import es_PA from './es_PA.json';
import es_AR from './es_AR.json';
import es_CL from './es_CL.json';
import es_CO from './es_CO.json';
import es_PE from './es_PE.json';
import es_MX from './es_MX.json';
import fr_CA from './fr_CA.json';
import ar_AE from './ar_AE.json';
import el_GR from './el_GR.json';
import nb_NO from './nb_NO.json';
import no_NO from './no_NO.json';
import de_CH from './de_CH.json';
import fr_CH from './fr_CH.json';
import de_AT from './de_AT.json';
import fr_BE from './fr_BE.json';
import nl_BE from './nl_BE.json';
import uk_UA from './uk_UA.json';
import en_ZA from './en_ZA.json';
import en_FI from './en_FI.json';

export default {
  de_DE,
  en_US,
  en_GB,
  fr_FR,
  es_ES,
  it_IT,
  it_CH,
  nl_NL,
  cs_CZ,
  sk_SK,
  ja_JP,
  jp_JP,
  ko_KR,
  pt_PT,
  pt_BR,
  ru_RU,
  pl_PL,
  hu_HU,
  sv_SE,
  da_DK,
  tr_TR,
  th_TH,
  ms_MY,
  zh_CN,
  zh_HK,
  zh_TW,
  es_PA,
  es_AR,
  es_CL,
  es_CO,
  es_PE,
  es_MX,
  fr_CA,
  ar_AE,
  el_GR,
  nb_NO,
  no_NO,
  de_CH,
  fr_CH,
  de_AT,
  fr_BE,
  nl_BE,
  uk_UA,
  en_ZA,
  en_FI
};
